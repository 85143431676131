<template>
    <div>
        <Accordion :multiple="true" :activeIndex="[0]">
            <AccordionTab :header="tab.label" v-for="tab in tabs" :key="tab.key">
                <div class="p-4">
                    <update-form
                        v-if="tab.fields.length > 0"
                        :type="tab.key"
                        :survey-parts="$parent.surveyParts"
                        :fields="tab.key !== 'structureFunctionalAreas' ? tab.fields : functionalAreas"
                    />
                </div>
            </AccordionTab>
        </Accordion>
    </div>
</template>

<script>
    import Accordion from 'primevue/accordion';
    import AccordionTab from 'primevue/accordiontab';
    import UpdateForm from "@/components/data/surveryPart/UpdateForm";

    export default {
        components: {
            UpdateForm,
            Accordion,
            AccordionTab
        },
        data() {
            return {
                functionalAreas: null
            }
        },
        mounted() {
            let fields = [];
            this.$http.get('surveys/' + this.$parent.editedEntity.id + '/functional-areas').then(response => {
                let _this = this;
                response.data['hydra:member'].forEach(function (item) {
                    fields.push({
                        label: item['name'],
                        key: item['@id'],
                        type: 'number',
                        unit: _this.$t('Number of employees')
                    });
                });
                this.functionalAreas = fields;
            });
        },
        computed: {
            tabs() {
                return [
                    {
                        label: this.$t('General information'),
                        //label: 'Allgemeine Angaben',
                        key: 'structureGeneral',
                        fields: this.structureGeneralFields
                    },
                    {
                        label: this.$t('Functional areas'),
                        //label: 'Funktionsbereiche',
                        key: 'structureFunctionalArea',
                        fields: this.functionalAreas
                    },
                    {
                        label: this.$t('Working time models'),
                        key: 'structureWorkingHour',
                        fields: this.structureWorkingHour
                    },
                    {
                        label: this.$t('Employment'),
                        key: 'structureEmploymentRelationship',
                        fields: this.structureEmploymentRelationship
                    },
                    {
                        label: this.$t('Seniority'),
                        key: 'structureSeniority',
                        fields: this.structureSeniority
                    }
                ]
            },
            structureWorkingHour() {
                return [
                    {
                        key: 'numberOfEmployeesFullTime',
                        label: this.$t('Employees full-time'),
                        //label: 'Mitarbeitende Vollzeit',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesPartTime',
                        label: this.$t('Employees part-time'),
                        //label: 'Mitarbeitende Teilzeit',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesShiftWork',
                        label: this.$t('Employees shift work'),
                        //label: 'Mitarbeitende Schichtdienst',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesFixedWorkingHours',
                        label: this.$t('Employees fixed working hours'),
                        //label: 'Mitarbeitende feste Arbeitszeiten',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesNotFixedWorkingHours',
                        label: this.$t('Employees flexible working hours'),
                        //label: 'Mitarbeitende flexible Arbeitszeiten',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                ]
            },
            structureSeniority() {
                return [
                    {
                        key: 'numberOfEmployeesEntryLowerThan2Years',
                        label: this.$t('Employees with less than 2 years of service'),
                        //label: 'Mitarbeitende mit einer Betriebszugehörigkeit von weniger als 2 Jahren',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesEntryBetween2And10Years',
                        label: this.$t('Employees with between 2 and 10 years of service'),
                        //label: 'Mitarbeitende mit einer Betriebszugehörigkeit zwischen 2 und 10 Jahren',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesEntryHigherThan10Years',
                        label: this.$t('Employees with more than 10 years of service'),
                        //label: 'Mitarbeitende mit einer Betriebszugehörigkeit länger als 10 Jahre',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                ]
            },
            structureEmploymentRelationship() {
                return [
                    {
                        key: 'numberOfEmployeesPermanent',
                        label: this.$t('Employees with permanent employment contracts'),
                        //label: 'Mitarbeitende unbefristete Arbeitsverträge',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesFixedTerm',
                        label: this.$t('Employees on fixed-term employment contracts'),
                        //label: 'Mitarbeitende befristete Arbeitsverträge',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                ]
            },
            structureGeneralFields() {
                return [
                    {
                        key: 'numberOfEmployees',
                        label: this.$t('Number of employees (total)'),
                        //label: 'Mitarbeitende gesamt',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesUnderThan30',
                        label: this.$t('Employees 30 years or younger'),
                        //label: 'Mitarbeitende 30 Jahre od. jünger',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesBetween30And45',
                        label: this.$t('Employees from 31 to 45 years'),
                        //label: 'Mitarbeitende ab 31 bis 45 Jahre',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesOlderThan40',
                        label: this.$t('Employees 46 years or older'),
                        //label: 'Mitarbeitende 46 Jahre od. älter',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesGenderFemale',
                        label: this.$t('Employees women'),
                        //label: 'Mitarbeitende Frauen',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesGenderMale',
                        label: this.$t('Employees men'),
                        //label: 'Mitarbeitende Männer',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfEmployeesGenderDivers',
                        label: this.$t('Employees diverse'),
                        //label: 'Mitarbeitende Divers',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                    {
                        key: 'numberOfExecutiveEmployees',
                        label: this.$t('Employees who hold a managerial position.'),
                        //label: 'Mitarbeitende, die eine Führungsposition innehaben.',
                        type: 'number',
                        unit: this.$t('Number of employees')
                    },
                ];
            }
        },
    }
</script>
